import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { Form, Formik, FormikHelpers } from 'formik';

import { useGlobalModalContext } from 'src/components/Modal/GlobalModal';
import ModalCloseButton from 'src/components/Modal/ModalCloseButton';
import { ModalComponent } from 'src/components/Modal/ModalComponent';
import SaveButton from 'src/components/Form/SaveButton';
import DenyButton from 'src/components/Form/DenyButton';
import TextInput from 'src/components/Form/TextInput';

interface Props {
  onAction: (request?: any) => Promise<any>;
}

interface NoticeDocumentsModalDto {
  test_email: string | null;
}

const NoticeDocumentsModal: React.FC<Props> = ({ onAction }) => {
  const { t } = useTranslation();
  const { hideModal } = useGlobalModalContext();
  const [request] = useState<NoticeDocumentsModalDto>({
    test_email: null,
  });

  const onSubmit = useCallback(
    async (request: NoticeDocumentsModalDto, helpers: FormikHelpers<NoticeDocumentsModalDto>) => {
      try {
        await onAction(request);
        hideModal();
      } catch (e: any) {
        helpers.setErrors(e.response?.errors);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onAction],
  );

  return (
    <React.Fragment>
      <ModalComponent size={'md'}>
        <div>
          <ModalHeader toggle={hideModal} close={<ModalCloseButton />}>
            {t('projects.debt_collection.send_email.title')}
          </ModalHeader>
          <Formik initialValues={request} onSubmit={onSubmit}>
            {({ handleSubmit, isSubmitting, values }) => (
              <Form onSubmit={handleSubmit}>
                <ModalBody>
                  {values.test_email ? (
                    <p className="text-danger">
                      {t('projects.debt_collection.send_email.test_body')}
                    </p>
                  ) : (
                    <p> {t('projects.debt_collection.send_email.body')}</p>
                  )}
                  <TextInput name="test_email" />
                </ModalBody>
                <ModalFooter className={'d-flex justify-content-center'}>
                  <Col>
                    <DenyButton title={t('common.cancel')} onClick={hideModal} />
                  </Col>
                  <Col>
                    <SaveButton
                      title={
                        values.test_email
                          ? t('projects.debt_collection.send_email.action_test_text')
                          : t('projects.debt_collection.send_email.action_text')
                      }
                      submitting={isSubmitting}
                    />
                  </Col>
                </ModalFooter>
              </Form>
            )}
          </Formik>
        </div>
      </ModalComponent>
    </React.Fragment>
  );
};

export default NoticeDocumentsModal;
