import * as Yup from 'yup';
import { translatableValueSchema } from 'src/helpers';

export const ProjectArticleCreateSchema = () => {
  const articleValidationSchema: any = {
    published_at: Yup.string().required(),
    title: translatableValueSchema(Yup.string().nullable().max(255)),
    value: translatableValueSchema(Yup.string().nullable().min(1).max(3000)),
  };

  return Yup.object().shape(articleValidationSchema);
};
